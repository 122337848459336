<template>
  <v-footer
    dark
    color="secondary"
  >
    <v-container>
      <v-row>
        <v-col cols="12" md="3" sm="6">
          <h2 class="mb-5">How It Works</h2>
          <div class="my-1">
            <a href="https://eazigear.com" class="text-decoration-none footer-link-hover" target="_blank">About Eazigear</a>
          </div>
          <!--
          <div class="my-1">
            <a href="https://eazigear.com/insurance" class="text-decoration-none footer-link-hover" target="_blank">Insurance</a>
          </div>
          -->
          <div class="my-1">
            <a href="https://eazigear.com/faq" class="text-decoration-none footer-link-hover" target="_blank">FAQs</a>
          </div>
        </v-col>

        <v-col cols="12" md="3" sm="6">
          <h2 class="mb-5">Terms</h2>
          <div class="my-1">
            <a href="https://eazigear.com/terms-of-use" class="text-decoration-none footer-link-hover" target="_blank">Terms of Service</a>
          </div>
          <div class="my-1">
            <a href="https://eazigear.com/privacy-policy" class="text-decoration-none footer-link-hover" target="_blank">Privacy Policy</a>
          </div>
          <div class="my-1">
            <a href="https://eazigear.com/trust-and-safety" class="text-decoration-none footer-link-hover" target="_blank">Trust &amp; Safety</a>
          </div>
        </v-col>

        <v-col cols="12" md="3" sm="6">
          <h2 class="mb-5">Contact</h2>
          <div class="my-1">
            <a href="https://eazigear.com/contact" class="text-decoration-none footer-link-hover" target="_blank">Contact Us</a>
          </div>
          <v-btn
            class="me-2 white--text mt-2"
            elevation="0"
            x-small
            fab
            dark
            color="secondary darken-2"
            href="https://www.facebook.com/eazigearZA"
            target="_blank"
          >
            <v-icon small size="24px">
              mdi-facebook
            </v-icon>
          </v-btn>
          <v-btn
            class="me-2 white--text mt-2"
            elevation="0"
            x-small
            fab
            dark
            color="secondary darken-2"
            href="https://twitter.com/eazigear"
            target="_blank"
          >
            <v-icon small size="24px">
              mdi-twitter
            </v-icon>
          </v-btn>
          <v-btn
            class="me-2 white--text mt-2"
            elevation="0"
            x-small
            fab
            dark
            color="secondary darken-2"
            href="https://www.instagram.com/eazigear_za/"
            target="_blank"
          >
            <v-icon small size="24px">
              mdi-instagram
            </v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12" md="3" sm="6">
          <p class="footer-text text-center">
            <v-img
              width="150"
              src="@/assets/eazigear_full_logo.png"
              alt=""
              style="display: block; margin: auto; width: 50%;" 
            ></v-img>
            {{ getVersion() }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  name: 'Footer',
  methods: {
    getContactPhone() {
      return process.env.VUE_APP_CONTACT_PHONE;
    },
    getContactEmail() {
      return process.env.VUE_APP_CONTACT_EMAIL;
    },

    getVersion() {
      return process.env.VUE_APP_COPYRIGHT + ' ' +  process.env.VUE_APP_VERSION_NUMBER;
    },
  },
};
</script>

<style lang="scss">
a.footer-link-hover {
  color: #bdbdbd !important;
  &:hover {
    color: #fff !important;
  }
}
.v-footer {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.footer-text {
  font-size: 0.6rem;
  text-transform: uppercase;
}
</style>